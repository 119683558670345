<template>
    <div class="page-product">
        <div class="columns is-multiline">
            <div class="column is-9">
                <h1 class="title">{{ product.name }}</h1>
            </div>
                <Carrusel v-bind:images=product.view_images />
            <div class="column is-3">
                
                <h2 class="subtitle">{{product.description}}</h2>
                <div v-if="product.variantes && product.variantes.length > 0">
                    <p>Variante:</p>
                    <select name="modelos" v-model="model"> 
                        <option v-for="modelos in product.variantes" 
                                v-bind:value="modelos">{{modelos.variante}}</option>
                    </select>
                    <br>
                    <h3 class="content">{{model.descripcion}}</h3>
                    <p><strong>Price: </strong>${{ model.price }}</p>
                </div>
                <div v-else>
                    <h3 class="content">{{product.descripcion}}</h3>
                    <p><strong>Price: </strong>${{ product.precio }}</p>
                </div>

                <div class="field has-addons mt-6">
                    <div class = "control">
                        <input type="number" class="input" min="1" v-model="quantity">
                    </div>
                    <div class = "control">
                        <a class="button is-dark" v-bind:disabled="product.enabled" @click="addToCart">Add to cart</a>
                    </div>
                </div>
            </div>
        </div>        
    </div>
</template>

<script>
import axios from 'axios'
import {toast} from 'bulma-toast'
import Carrusel from '../components/Carrusel'
export default {
  components: { Carrusel },
    name: 'Product',
    data() {
        return {
            product: {},
            mid: 0,
            model: {},
            quantity: 1
        }
    },
    mounted() {
        this.getProduct()
    },
    methods: {
        async getProduct() {
            this.$store.commit('setIsLoading', true)

            const category_slug = this.$route.params.category_slug
            const product_slug = this.$route.params.product_slug

            await axios
                .get(`/api/v1/products/${category_slug}/${product_slug}`)
                .then(response => {
                    this.product = response.data
                    if (this.product.get_models.length > 0) 
                    {
                        this.mid = this.product.get_models[0];
                    }
                    else
                    {
                        this.mid = false;
                    }

                    document.title = this.product.name + ' | Limoni'
                })
                .catch(error => {
                    console.log(error)
                })
            this.$store.commit('setIsLoading', false)
        },
        addToCart() {
            if (isNaN(this.quantity) || this.quantity < 1) {
                this.quantity = 1
            }

            const item = {
                product: this.product,
                quantity: this.quantity
            }

            this.$store.commit('addToCart', item)

            toast({
                message: 'The product was added to the cart',
                type: 'is-success',
                dismissible: true,
                pauseOnHover: true,
                duration: 2000,
                position: 'bottom-right'
            })
        }
    },
}
</script>
