<template>
  <div class="home">
    <section class="hero is-medium is-light mb-6">
      <div class ="hero-body has-text-centered">
        <figure class="image is-3by1 ">
          <img src="logo.png" alt="">
          </figure>
          <p><br></p>
        <p class="subtitle">
          Endulza tu vida con los sabores irresistibles de Limoni Cocina Dulce
        </p>
      </div>
    </section>
    <div class="columns is-multiline">
      <div class="column is-12">
          <h2 class="is-size-2 has-text-centered">Latest products</h2>
      </div>

      <ProductBox
        v-for="product in latestProducts"
        v-bind:key="product.id"
        v-bind:product="product" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import ProductBox from '@/components/ProductBox'

export default {
  name: 'Home',
  data(){
    return{
      latestProducts: []
    }
  },
  components: {
    ProductBox
  },
  mounted(){
    this.getLatestProducts()
    document.title = ' Home | Limoni'
  },
  methods: {
    /*getLatestProducts(){
      axios
        .get('/api/v1/latest-products/')
        .then(response => {
          this.latestProducts = response.data
        })
        .catch(error => {
          console.log(error)
        })*/
        async getLatestProducts() {
      this.$store.commit('setIsLoading', true)

      await axios
        .get('/api/v1/latest-products/')
        .then(response => {
          this.latestProducts = response.data
        })
        .catch(error => {
          console.log(error)
        })

      this.$store.commit('setIsLoading', false)
    }
  },
}
</script>

<style scoped>

</style>